const credentials = {
    mapsKey:"AIzaSyAKcb-dJyv2UgorrORtBUK_SUCivTRBLcg",
    captchaKey:"6LfQxBsnAAAAAGjqNv6YyWlEOn6Gdm0xlLZvuxAb",
    emailJs:{
        id:"gFpiIvrXnj4ZS_Jvk",
        service: 'service_3yijfdi',
        template: 'template_klv1kp6'
    },
    whatsappApi:"https://api.whatsapp.com/send?phone=5491164925109&text=%C2%A1Hola!%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Quintas%20del%20Este",
    api: {
        key:'Quintas1234!',
        testUrl: 'https://quintas-del-oeste-testing.fly.dev/api/',
        devUrl: 'http://localhost:3030/api/',
        url: 'https://quintasdeleste.herokuapp.com/api/auth'
    },
    helloSign:{
        clientID: '2ea6696b79a25d369b6f09e562128bd0',
        apiKey: '159d0ceec21251cece8b7ec2ff498e61ed1761d3dbe0de42456bb976e416b427'
    },
    firebaseConfig: {
        development:{
            apiKey: "AIzaSyDEmIyi6MgMnJ2jAE_NNHlQhpCkOVtMDG8",
            authDomain: "quintas-del-oeste.firebaseapp.com",
            projectId: "quintas-del-oeste",
            storageBucket: "quintas-del-oeste.appspot.com",
            messagingSenderId: "937774311186",
            appId: "1:937774311186:web:46dba57adb7216c4def5cb"
        },
        production:{
            apiKey: "AIzaSyBSLG3wyZunqi42LiBJzglXmlK5YErKYz8",
            authDomain: "quintas-del-oeste-417ab.firebaseapp.com",
            projectId: "quintas-del-oeste-417ab",
            storageBucket: "quintas-del-oeste-417ab.appspot.com",
            messagingSenderId: "655058385074",
            appId: "1:655058385074:web:53cc7479dc695606b1f1ab"
        }
    },
    mercadoPago:{
        publicKey:'TEST-9cc638bc-e181-453a-9b8c-03922deb09ae'
    }
}
export default credentials